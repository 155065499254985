<template>
  <div>
    <NavBar title=""/>
    <v-main>
      <v-container fluid>
        <Tabs class="">
          <Tab label="Open Findings">
            <OpenFindings>
<!--               <template
                v-slot:proposed="{ finding }"
              >
                <Proposed :finding="finding" />
              </template>
              <template
                v-slot:cas="{ finding }"
              >
                <CAs :finding="finding" />
              </template> -->
            </OpenFindings>
          </Tab>
          <Tab label="Findings Archive">
            <Archive />
          </Tab>
        </Tabs>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Archive from '@/components/shared/archive'
import OpenFindings from '@/components/shared/open-findings'
import NavBar from '@/components/UI/nav-bar'

export default {
  name: 'FSHome',
  components: {
    Archive,
    OpenFindings,
    NavBar,
  },
  mounted() {
    this.$emit('loaded')
  },
  setup() {
    return {}
  }
}
</script>
